"use client"
import React,{useEffect} from 'react'
// import { Link} from 'react-router-dom';
import Link from 'next/link';  // Use Next.js's Link component
import lpage from './images/Landing/Entry_Page.jpg';
import lpage_2x from './images/Landing/Entry_Page@2x.jpg';
import Image from 'next/image';

import { useRouter } from 'next/navigation';

const LandingPage =() => {
  //const navigate = useNavigate();
  const router= useRouter();

  useEffect(() => {

    // Function to check if the device is mobile
    const isMobileDevice = () => {
      if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iPad|iPhone|iPod/i.test(userAgent);
      }
      return false;
    };

    // Redirect to the home page after 7 seconds
    const timer = setTimeout(() => {
    //   navigate('/home');
    router.push('/home');
    }, 7000);

    if (isMobileDevice()) {
      router.push('/home');
    }

    // Add keydown event listener for ESC key
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {  // ESC key is pressed
        // navigate('/home');
        router.push('/home');
      }
    };

    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup both the timeout and event listener on component unmount
    return () => {
      clearTimeout(timer);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [router]);

  return (
    // <div className='no-scroll h-screen overflow-hidden w-full flex flex-col relative'>
    <div className='relative w-full h-screen'>
  <Link href="/home" className="relative block w-full h-full">
 
        <Image src={lpage_2x} className='w-full h-auto object-cover' alt="ASHLOK CAN YOU PROTECT YOURSELF FROM SOMETHING YOU CAN&apos;T SEE? YES&#33;&apos; emphasizing safety, protection, and security in earthing and lightning protection solutions"  fill priority/>
      
        </Link>
       
        <Link href="/home">
        <p  className=' text-white sm:text-xl md:text-2xl lg:text-3xl' style={{position:'absolute',left:'80%',top:'80%',transform: 'translate(-50%, -50%)'}}>Click Here to Find Out</p>
        
        </Link>
    </div>
  )
}
export default  LandingPage